import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Project from '../components/project'

export const query = graphql`
	query InfoAndContactQuery {
		info:sanityInfoAndContact {
			title
			panorama {
				asset {
					_id
				}
				alt
			}
			locationName
			coordinates {
				lat
				lng
			}
			timezone
			gallery {
				asset {
					_id
				}
				alt
				caption
			}
			misc
			_rawBody
		}
	}
`

const InfoPage = (props) => {
	const {data, errors} = props
	const info = data && data.info
	return (
		<Layout>
			{console.log('data', data)}
			{errors && <SEO title='GraphQL Error' />}
			{info && <SEO title={info.title || 'Untitled'} />}

			{errors && (
			  <Container>
				<GraphQLErrorList errors={errors} />
			  </Container>
			)}
			{data && <Project {...info} />}
		</Layout>
	)
}

export default InfoPage
